const modalState = reactive({
  isOpen: false,
  slug: '',
})

const showModal = (slug: string = '') => {
  modalState.isOpen = true;
  modalState.slug = slug
  // Object.assign(modalState, { isOpen: true, slug })
}
const hideModal = () => modalState.isOpen = false

export {
  hideModal,
  showModal,
  modalState,
}
