<script setup lang="ts">
import type { IOrganization } from '~/types/rating'

import { useRating } from '~/composables/useRating'
import { hideModal, modalState } from '~/store/modal/CertificateModal'
import RModal from '~/components/Base/rModal.vue'

const { fetchOrganization, fetchTypes, fetchWordForms } = useRating()
const { copy } = useClipboard()
const route = useRoute()
const { $config: { public: { BASE_URL } } } = useNuxtApp()

const org = ref<null | IOrganization>(null)
const wordForms = ref(null)
const isLoading = ref(true)
const isCopied = ref(false)

// function copyLink() {
//   copy(`${BASE_URL}${route.fullPath.slice(1)}`)

//   isCopied.value = true
//   setTimeout(() => {
//     isCopied.value = false
//   }, 3000)
// }

onMounted(async () => {
  if (modalState.slug) {
    try {
      org.value = (await fetchOrganization(modalState.slug)).organization
      await fetchTypes(org.value.city_id)
      wordForms.value = await fetchWordForms(
        org.value.city_id,
        org.value.rating_type_id,
      )
      isLoading.value = false
    }
    catch (e) {
      console.error(e)
      hideModal()
    }
  }
})
</script>

<template>
  <RModal v-model="modalState.isOpen" :is-blured="false" :dark-bg="true" :fixed-width="false" padding="px-4 pt-4 md:px-8 md:pt-8 duration-150">
    <div v-if="!isLoading" class="relative flex flex-col items-center justify-center gap-3 show w-full lg:w-[1000px] font-golos px-3 md:px-0 pb-14 md:pb-24 duration-150">
      <svg
        class="block absolute top-0 right-0 cursor-pointer hover:text-blue-500 duration-150"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        @click="hideModal"
      >
        <path
          fill="currentColor"
          d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275t.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7t-.7.275t-.7-.275z"
        />
      </svg>
      <div class="relative">
        <svg xmlns="http://www.w3.org/2000/svg" width="291" height="281" viewBox="0 0 291 281" fill="none">
          <rect width="209" height="209" rx="10" transform="matrix(0.998918 0.0465084 -0.0668078 0.997766 44 43)" fill="#FFDF34"/>
          <path d="M147.715 3.04635C149.786 -0.762082 155.326 -0.504114 156.875 3.47285L190.532 89.87C191.161 91.4869 192.591 92.6472 194.311 92.9378L286.23 108.471C290.462 109.186 291.821 114.529 288.478 117.305L215.857 177.605C214.498 178.733 213.806 180.471 214.028 182.199L225.856 274.535C226.401 278.785 221.7 281.829 218.085 279.568L139.547 230.438C138.077 229.519 136.22 229.432 134.636 230.21L50.0274 271.743C46.1328 273.655 41.8683 270.193 42.9768 266.02L67.0584 175.356C67.509 173.659 67.0533 171.869 65.8533 170.621L1.73368 103.954C-1.21782 100.885 0.846704 95.7019 5.14701 95.3841L98.5687 88.4802C100.317 88.351 101.892 87.3306 102.734 85.7823L147.715 3.04635Z" fill="#f6f7f8"/>
          <defs>
            <linearGradient id="paint0_linear_7113_9882" x1="152.955" y1="-6.59134" x2="138.141" y2="311.592" gradientUnits="userSpaceOnUse">
              <stop offset="1" stop-color="white"/>
              <stop offset="1" stop-color="#FFDE33"/>
            </linearGradient>
          </defs>
        </svg>
        <div class="absolute top-24 left-0 w-full flex flex-col items-center" style="transform: rotate(3deg);">
          <p class="text-[32px] font-bold" v-text="`Топ ${ org.top.percent }%`" />
          <p class="w-32 text-[12px] text-center font-bold" v-text="`${ wordForms.type.regularWordForm.genitive } ${ wordForms.city.regularWordForm.genitive }`" />
        </div>
      </div>
      <h2 class="md:w-[750px] text-m-h3 md:text-3xl md:text-[48px] leading-8 md:leading-[2.8rem] font-bold md:mb-4 text-center" v-html="`${org.name}${org.metro.length > 0 ? `, ${org.metro}` : ''}`" />
      <p class="md:w-[700px] text-center">
        Аналитическое агентство «Ранг Интеллект» подтверждает, что по результатам 3&nbsp;квартала&nbsp;2024 года, клиника «{{ org.name }}» на {{ org.address }}{{org.metro.length > 0 ? `, ${org.metro}` : ''}}
        <strong>входит в Топ {{ org.top.percent }}% лучших {{ wordForms.type.regularWordForm.genitive }} {{ wordForms.city.regularWordForm.genitive }}.</strong>
      </p>
      <!-- <RButton
        :color="isCopied ? 'primary-loading' : 'secondary'"
        :class="{ 'text-white': isCopied }"
        class="w-full md:w-[190px] h-12 mt-3"
        @click="copyLink"
      >
        <div class="flex gap-2 items-center">
          <template v-if="isCopied">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM3.63636 8.05167L6.72192 11.1372L11.8645 5.99463L10.836 4.96611L6.72192 9.08018L4.66488 7.02315L3.63636 8.05167Z" fill="white"/>
            </svg>
            <p>Скопировано</p>
          </template>
          <template v-else>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8.66667 4.66663H4.66667V3.33329H8.66667V4.66663Z" fill="#0B74B0"/>
              <path d="M8.66667 7.33329H4.66667V5.99996H8.66667V7.33329Z" fill="#0B74B0"/>
              <path d="M4.66667 9.99996H8.66667V8.66663H4.66667V9.99996Z" fill="#0B74B0"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12.6666V0.666626H11.3333V3.33329H14V15.3333H4.66667V12.6666H2ZM10 11.3333V1.99996H3.33333V11.3333H10ZM11.3333 4.66663V12.6666H6V14H12.6667V4.66663H11.3333Z" fill="#0B74B0"/>
            </svg>
            <p>Поделиться</p>
          </template>
        </div>
      </RButton> -->
    </div>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="64px"
      height="64px"
      viewBox="0 0 24 24"
      class="w-full md:w-fit pl-0.5 pb-4 md:pb-8 duration-150"
    >
      <path
        fill="currentColor"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        opacity="0.25"
      />
      <path
        fill="currentColor"
        d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
      >
        <animateTransform
          attributeName="transform"
          dur="0.75s"
          repeatCount="indefinite"
          type="rotate"
          values="0 12 12;360 12 12"
        />
      </path>
    </svg>
  </RModal>
</template>

<style scoped>
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.show {
  animation: show 200ms ease-in-out;
}
</style>
