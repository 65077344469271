<script setup lang="ts">
import { hideModal, modalState } from '~/store/modal/CodeModal'
import RModal from '~/components/Base/rModal.vue'

const { $config } = useNuxtApp()

const code = ref(`<iframe frameborder="0" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:300px;width:100%;position:relative;top:0px;left:0px;right:0px;bottom:0px" src="${$config.public.BASE_URL}rating/embed/${modalState.slug}"></iframe>`)

const { copy } = useClipboard()

function selectAndCopy(el: MouseEvent) {
  el.target.select()
  copy(code.value)
}

watch(modalState, val => {
  code.value = `<iframe frameborder="0" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:300px;width:100%;position:relative;top:0px;left:0px;right:0px;bottom:0px" src="${$config.public.BASE_URL}rating/embed/${val.slug}"></iframe>`
  copy(code.value)
})
</script>

<template>
  <RModal v-model="modalState.isOpen">
    <div class="relative">
      <Icon
        class="absolute top-0 right-0 cursor-pointer hover:text-blue-500 duration-150"
        name="mdi:close"
        width="24px"
        height="24px"
        @click="hideModal"
      />
      <h2 class="md:text-h2 text-m-h2">
        Добавьте сертификат<br>на&nbsp;ваш сайт
      </h2>
      <p class="md:text-primary text-m-primary pt-3">
        Тег уже скопирован, осталось добавить его в ваш код сайта.<br>Блок динамический и может использоваться в любых удобных сценариях 👍
      </p>
      <textarea
        class="font-mono mt-3 text-black border px-3 min-h-24 pt-2 w-full rounded-md border-black focus:outline-0 focus:border-black duration-150"
        type="textarea"
        col="4"
        :value="code"
        @click="selectAndCopy"
      />
    </div>
  </RModal>
</template>
