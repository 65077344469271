<script setup>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import AuditSection from '@/components/AuditSection.vue'
import SubscribeSection from '@/components/SubscribeSection.vue'
import AuditModal from '@/components/modals/AuditModal.vue'
import TopModal from '@/components/modals/TopModal.vue'
import FreeModal from '@/components/modals/FreeModal.vue'
import CodeModal from '@/components/modals/CodeModal.vue'
import CertificateModal from '@/components/modals/CertificateModal.vue'
import { showModal } from '~/store/modal/CertificateModal'

const query = useRoute().query

if (query.org) {
  showModal(query.org)
}
</script>

<template>
  <div class="min-h-screen flex flex-col">
    <!-- Modals -->
    <CertificateModal />
    
    <ClientOnly>
      <AuditModal />
      <TopModal />
      <FreeModal />
      <CodeModal />
    </ClientOnly>

    <Header class="main-header" />

    <main>
      <slot />
    </main>
    <AuditSection v-if="$route.path.split('/')[1] !== 'events'" />
    <SubscribeSection v-if="$route.path === '/'" />

    <Footer />
  </div>
</template>

<style lang="scss">
</style>
