<script setup lang="ts">
const props = withDefaults(defineProps<{
  isBlured?: boolean
  darkBg?: boolean
  fixedWidth?: boolean
  padding?: string
}>(), {
  isBlured: true,
  darkBg: false,
  fixedWidth: true,
  padding: "pl-4 pt-12 pr-6 pb-20 md:pl-10 md:pt-12 md:pr-16 md:pb-20",
})

const modelValue = defineModel()

onMounted(() => {
  const closeModalOnEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && modelValue.value)
      modelValue.value = false
  }

  window.addEventListener('keydown', closeModalOnEsc)

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', closeModalOnEsc)
  })
})

watchEffect(() => {
  if (process.client) {
    const body = document.body
    const scrollBarWidth = window.innerWidth - body.clientWidth

    if (modelValue.value) {
      document.body.style.overflow = 'hidden'
      if (scrollBarWidth > 0)
        body.style.paddingRight = `${scrollBarWidth}px`
    }

    else {
      document.body.style.overflow = 'auto'
      body.style.paddingRight = '0px'
    }
  }
})
</script>

<template>
  <Transition>
    <teleport v-if="modelValue" to="body">
      <div
        class="fixed z-50 inset-0 overflow-y-auto bg-opacity-90 show-bg"
        :class="{
          'backdrop-blur-sm': props.isBlured,
          'bg-white': !props.darkBg,
          'bg-[#26262652]': props.darkBg,
        }"
        @click="modelValue = !modelValue"
      >
        <div class="inset-center w-full md:w-auto max-h-[90vh] overflow-y-auto" @click.stop>
          <div
            :class="[{ 'w-full md:max-w-[604px]': fixedWidth }, props.padding]"
            class="bg-milky-200 rounded-lg overflow-hidden shadow-xl mx-3 show-container"
            role="dialog"
            aria-modal="false"
            aria-labelledby="modal-headline"
          >
            <div v-bind="$attrs" class="flex flex-col">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </Transition>
</template>

<style scoped>
@keyframes showContainer {
  0% {transform: scale(0.8)}
  100% {transform: scale(1)}
}

@keyframes showBg {
  0% {opacity: 0}
  100% {opacity: 1}
}

.inset-center {
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show-container {animation: showContainer ease-in-out 150ms}
.show-bg {animation: showBg ease-in-out 150ms}

.v-enter-active,
.v-leave-active {
  transition: opacity 150ms ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
